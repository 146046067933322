import React, { FC, ReactNode } from "react";
import { I18nextProvider } from "react-i18next";
import { i18n } from "~/libs/i18n/init";

export const I18NProvider: FC<{
  children: ReactNode;
}> = ({ children }) => {
  return (
    <I18nextProvider i18n={i18n} defaultNS={"game"}>
      {children}
    </I18nextProvider>
  );
};
