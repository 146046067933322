import { env } from "~/env.mjs";
import type { InitOptions } from "i18next/typescript/options";
import { LOCAL_STORAGE_SETTINGS_KEY } from "~/client/hooks/userSettings";

export const fallbackLng = "en";
export const languages = [fallbackLng, "de", "fr", "pt-BR"];
export const defaultNS = "game";

const myDetector = {
  name: "myDetector",

  lookup() {
    try {
      return (
        JSON.parse(localStorage.getItem(LOCAL_STORAGE_SETTINGS_KEY) || "{}")
          ?.language || "en"
      );
    } catch (e) {
      console.error(e);
      return "en";
    }
  },
};

export function getOptions(lng = fallbackLng, ns = defaultNS): InitOptions {
  return {
    detection: {
      order: ["myDetector", "localStorage", "sessionStorage"],
      caches: ["localStorage", "sessionStorage"],
    },
    backend: {
      loadPath: `/locales/${env.NEXT_PUBLIC_TENANT}/{{lng}}/{{ns}}.json`,
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    load: "languageOnly",
    ns,
    defaultNS: defaultNS,
    debug: false,
    supportedLngs: languages,
    fallbackLng,
    lng,
  };
}
