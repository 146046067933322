import i18nModule from "i18next";
import { initReactI18next } from "react-i18next/initReactI18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { getOptions } from "./settings";

i18nModule
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Backend)
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // learn more: https://github.com/i18next/react-i18next
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(getOptions("en", "game"));

export const i18n = i18nModule;
